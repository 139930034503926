import React from "react";
import { useI18next, Link } from "gatsby-plugin-react-i18next";
import { useMediaQuery } from "react-responsive";

import { useNavigation } from "./useNavigation";
import { LanguageSelector } from "./LanguageSelector";

export const Header = (): JSX.Element | null => {
  const navigations = useNavigation();
  const { language } = useI18next();
  const isMobile = useMediaQuery({ maxWidth: 867 });

  const NavigationLink = ({
    item,
    path,
  }: {
    item: any;
    path: string;
  }): JSX.Element => {
    const onLinkClicked = () => {
      if (!isMobile) {
        return;
      }

      const element = document.getElementById("hs-hamburger-toggle");
      if (element) {
        element.click();
      }
    };

    if (item.type === "INTERNAL") {
      if (!path.startsWith("/")) {
        path = "/" + path;
      }
      path = path.replace(/\/?$/, "/");

      return (
        <Link
          className="flex items-center gap-x-2 py-2 px-2 px-42 mx-1 rounded-md font-medium text-slate-700 hover:bg-gray-200 md:focus:ring-2 md:focus:ring-blue-500"
          to={path}
          language={language}
          onClick={onLinkClicked}
          target="_self"
        >
          {item.title}
        </Link>
      );
    } else {
      return (
        <a
          target="_blank"
          href={item.externalPath}
          className="flex items-center gap-x-2 py-2 px-2 mx-1 rounded-md font-medium text-slate-700 hover:bg-gray-200 md:focus:ring-2 md:focus:ring-blue-500"
        >
          {item.title}
        </a>
      );
    }
  };

  if (navigations) {
    return (
      <>
        <header className="sticky top-0 left-0 flex flex-wrap md:justify-start md:flex-nowrap z-50 w-full py-3 px-4 md:py-0 bg-blue-200 dark:bg-white">
          <nav
            className="relative w-full mx-auto md:flex md:items-center md:justify-between px-6 3xl:px-72"
            aria-label="Global"
          >
            <div className="flex items-center justify-between">
              <Link
                className="flex-none text-xl font-semibold z-10"
                to="/"
                language={language}
                aria-label="Brand"
              >
                <div className="flex normal-case text-xl">
                  <img
                    src="/images/logo.png"
                    className="my-2 mr-2 h-10 sm:h-12 md:h-16 lg:h-20 stroke-white"
                  />
                  <span className="self-center lg:whitespace-nowrap text-sm md:text-lg font-semibold text-slate-800">
                    {isMobile ? "Spol" : "Puhallinorkesteriliitto"}
                  </span>
                </div>
              </Link>
              <div className="md:hidden">
                <button
                  type="button"
                  id="hs-hamburger-toggle"
                  className="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white shadow-sm align-middle hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:text-slate-600 dark:border-gray-700 "
                  data-hs-collapse="#navbar-collapse-with-animation"
                  aria-controls="navbar-collapse-with-animation"
                  aria-label="Toggle navigation"
                >
                  <svg
                    className="hs-collapse-open:hidden w-4 h-4"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                    />
                  </svg>
                  <svg
                    className="hs-collapse-open:block hidden w-4 h-4"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </button>
              </div>
            </div>
            <div
              id="navbar-collapse-with-animation"
              className="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow md:block"
            >
              <div className="flex flex-col gap-y-4 gap-x-0 mt-5 md:flex-row md:items-center md:justify-end md:gap-y-0 md:gap-x-3 md:mt-0 md:pl-7">
                {navigations.map((item: any) => {
                  if (item.menuAttached) {
                    if (item.childItems.length > 0) {
                      return (
                        <div
                          key={item.id}
                          className="hs-dropdown [--strategy:static] md:[--strategy:fixed] [--adaptive:none] md:[--trigger:hover] [--auto-close:true]"
                        >
                          <button
                            type="button"
                            className="flex items-center gap-x-2 py-2 px-2 mx-1 rounded-md font-medium text-slate-700 hover:bg-gray-200 md:focus:ring-2 md:focus:ring-blue-500"
                          >
                            {item.title}
                            <svg
                              className="ml-2 w-2.5 h-2.5 text-slate-600"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                              ></path>
                            </svg>
                          </button>

                          <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] md:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 md:w-auto hidden z-10 bg-blue-200 md:bg-white dark:bg-white md:shadow-md rounded-lg p-2 before:absolute top-full md:border before:-top-5 before:left-0 before:w-full before:h-5">
                            <div className="max-h-[calc(100vh-100px)] overflow-y-auto">
                              {item.type !== "WRAPPER" && (
                                <NavigationLink
                                  key={item.id}
                                  path={`${item.path}`}
                                  item={item}
                                />
                              )}
                              {item.childItems.map((child: any) => {
                                if (child.menuAttached) {
                                  return (
                                    <NavigationLink
                                      key={child.id}
                                      path={`${item.path}-${child.path}`}
                                      item={child}
                                    />
                                  );
                                } else {
                                  return null;
                                }
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <NavigationLink
                          key={item.id}
                          path={`${item.path}`}
                          item={item}
                        />
                      );
                    }
                  } else {
                    return null;
                  }
                })}

                <div className="mt-2 py-2 md:py-0 md:mt-6 flex w-full md:w-auto gap-x-2 items-baseline md:items-center border-t md:border-t-0 md:border-l border-gray-700 md:my-6 md:pl-6 ">
                  <LanguageSelector />
                </div>
              </div>
            </div>
          </nav>
        </header>
        <svg
          className="wave w-full bg-blue-200 dark:bg-white fill-gray-100 dark:fill-slate-900"
          id="wave"
          preserveAspectRatio="none"
          viewBox="0 0 1200 50"
        >
          <path d="M 0, 27.80808345929001 Q 33.25, 27.80808345929001 66.5, 18.851539169705603 Q 99.75, 9.894994880121196 133, 9.894994880121196 Q 166.25, 9.894994880121196 199.5, 21.616105724631762 Q 232.75, 33.33721656914233 266, 33.33721656914233 Q 299.5, 33.33721656914233 333, 25.568895805482214 Q 366.5, 17.8005750418221 400, 17.8005750418221 Q 433.25, 17.8005750418221 466.5, 21.23500173878141 Q 499.75, 24.66942843574072 533, 24.66942843574072 Q 566.25, 24.66942843574072 599.5, 30.18845240890458 Q 632.75, 35.70747638206844 666, 35.70747638206844 Q 699.5, 35.70747638206844 733, 31.52804489266321 Q 766.5, 27.348613403257982 800, 27.348613403257982 Q 833.25, 27.348613403257982 866.5, 28.617675637767157 Q 899.75, 29.886737872276335 933, 29.886737872276335 Q 966.25, 29.886737872276335 999.5, 28.517540622082528 Q 1032.75, 27.14834337188872 1066, 27.14834337188872 Q 1099.5, 27.14834337188872 1133, 28.41159425234546 Q 1166.5, 29.6748451328022 1200, 29.6748451328022 L 1200, 50 L 0, 50 Z"></path>
        </svg>
      </>
    );
  }

  return null;
};
